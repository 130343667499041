/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('../../assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('../../assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('../../assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

@font-face {
	font-family: RalewayIt;
	src: url('../../assets/font/Raleway-Italic-VariableFont_wght.ttf');
	font-weight: 400;
}

@font-face {
	font-family: Raleway;
	src: url('../../assets/font/Raleway-VariableFont_wght.ttf');
	font-weight: 400;
}
